import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

// Utils
import { repositoryConfigs } from './src/utils/prismicPreviews'
import { linkResolver } from './src/utils/linkResolver'

import '@fontsource/lato'
import '@fontsource/vesper-libre'
import './src/styles/default.css'

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    externalLinkComponent={(props) => {
      const childProps = { ...props }
      delete childProps.activeClassName
      childProps.target = props.target || '_blank'
      childProps.rel = props.rel || 'noopener noreferrer'
      return (
        <a data-location='external' {...props}>
          {props.children}
        </a>
      )
    }}
    internalLinkComponent={({ href, ...props }) => <Link to={href} {...props} />}>
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
  </PrismicProvider>
)
